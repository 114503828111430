<template>
  <div style="width: 1200px;margin: 0 auto">

    <div class="title" style="margin-top: 30px;text-align: center;font-size: 20px;font-weight: bold;">{{ detail.title }}</div>
    <div class="title" style="margin-top: 10px;text-align: center;font-size: 14px;">发布日期:{{ detail.create_time }}</div>
    <div class="ql-container ql-snow info-box" style="border: initial;margin-top: 30px">
      <div class="ql-editor" v-html="detail.info"></div>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  name: "noticeInfo",
  data() {
    return {
      id: '',
      detail: ""
    }
  },
  created() {
    this.id = this.$route.query.id;
    setTimeout(() => {
      //异步执行列表刷新
      this.getInfo();
    }, 0)
  }
  ,
  methods: {
    getInfo() {
      let _this = this;
      _this.axios.get('/ebook/get_notice_info?id=' + _this.id)
          .then(function (res) {
            if (res.code == 200) {
              _this.detail = res.data;
            } else {
              _this.$message.error(res.msg);
            }
          });

    }
  }
}
</script>

<style scoped>

</style>